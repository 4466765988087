<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <div class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100vw; outline:0.0em solid black; margin:auto; ">  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; "> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td>
              <table width="100%" border=0 s>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;">Testimonials</span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="thankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="doShowHome" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
        </table>
        <table @click="this.doGoogleReviews();" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td style="padding-top: 1vh; padding-bottom: 1vh; line-height: 0.1px">              
                <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.8 + 'px', }">Below are our Google Reviews at the beginning of September 2022, tap <a href="https://www.google.com/search?q=thanku+cashless+tipping&source=hp&ei=NO0YY_nOB5aTgQbxqIiYDw&iflsig=AJiK0e8AAAAAYxj7RONvBoQ2ViOhSFazoM8QSQle-Eru&ved=0ahUKEwj5uMLLsYP6AhWWScAKHXEUAvMQ4dUDCAo&uact=5&oq=thanku+cashless+tipping&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEB4QFjIFCAAQhgMyBQgAEIYDMgUIABCGAzIFCAAQhgMyBQgAEIYDOg4IABCPARDqAhCMAxDlAjoOCC4QjwEQ6gIQjAMQ5QI6CwgAEIAEELEDEIMBOggILhCxAxCDAToRCC4QgAQQsQMQgwEQxwEQ0QM6DgguEIAEELEDEMcBENEDOgUIABCABDoLCC4QgAQQsQMQgwE6BQguEIAEOgsILhCxAxCDARDUAjoICAAQgAQQsQM6CwguEIAEELEDENQCOgsILhCABBDHARDRAzoECAAQAzoOCC4QgAQQsQMQgwEQ1AI6CAguEIAEELEDOgcIABCABBAKOgcIABCxAxAKOgQIABAKOgQILhAKOgoILhDHARCvARAKOgoILhDHARCvARANOgQIABANOggIABAeEA0QBToMCAAQHhAPEAgQDRAKOgoIABAeEA8QCBANOgcIIRCgARAKOggIIRAeEBYQHVDuBFiQH2CWIGgBcAB4AIABU4gBrgmSAQIyM5gBAKABAbABCg&sclient=gws-wiz#lrd=0x487605604ae6b115:0x72d4727c1f762f01,1" target=blank> here</a> for a live link to our current Google Reviews - if you very kindly wish to leave a Google review,  <a href="https://g.page/r/CQEvdh98ctRyEAI/review" target=blank> this is our direct Google review link</a> which we have recently set up. Even 2 or 3 words is much appreciated. thankU! <br><br>Adrian, creator of thankU cashless tipping</span> 
            </td>

          </tr>
          <tr>
            <td width=100%>
              <img  class="tulogo" style="width: 100%; height: auto;" alt="testimonials" src="../assets/googleheader.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp1.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp2.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp3.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp4.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp5.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp6.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp7.png">
           </td>
          </tr>
         <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/googlereviewsp8.png">
           </td>
          </tr>
        </table>
        <table @click="this.doAppStoreReviews();" width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
         <tr>

            <td style="padding-top: 1vh; padding-bottom: 1vh; line-height: 0.1px">              
                <span class="lt-blue" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.8 + 'px', }">Below are some reviews of thankU on Apple's App Store. As I have now launched this new QR version of thankU and will be retiring the App Store version some time in Q4 2022, I imagine these reviews will no longer be visible on the App Store at some point but for now <a href="https://apps.apple.com/gb/app/thanku/id1350131257?see-all=reviews" target=blank> this is the link to them.</a></span> 
            </td>
          </tr>
          
          <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/testimonialpanel1.jpg">
           </td>
          </tr>
          <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/testimonialpanel2.jpg">
           </td>
          </tr>
          <tr>
            <td width=100%>
              <img style="width: 100%; height: auto" class="tulogo" alt="testimonials" src="../assets/testimonialpanel3.jpg">
           </td>
          </tr>

        </table>       
      </div>
    </div>
</template>

<script>
const Parse = require('parse/node');
import shared from '../shared.js';

export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  data() {
    return {
      fontSizeNormal: 3.0, // vh
      fontSizeNormalInPXToUse: "",
      fontSizeNormalLineHeight: 4.0,// vh
      fontSizeNormalInPXLineHeightToUse: "",
      vhMult: 1,
    }
  },
  methods: {
    doFontSizeComputed () {
      
      this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
      this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
    
    },
    doShowGetTipsStart(){
      // let returnJSON = { showGetTipsStart: true };
      let returnJSON = { getTipsStartNew: true };
      this.$emit('return-json', returnJSON); // this is activating the router so no need to go back


    },
    doAppStoreReviews() {
        const url = "https://apps.apple.com/gb/app/thanku/id1350131257?see-all=reviews";

        window.open(url);
    },
    doGoogleReviews(){
        const url = "https://www.google.com/search?q=thanku+cashless+tipping&source=hp&ei=NO0YY_nOB5aTgQbxqIiYDw&iflsig=AJiK0e8AAAAAYxj7RONvBoQ2ViOhSFazoM8QSQle-Eru&ved=0ahUKEwj5uMLLsYP6AhWWScAKHXEUAvMQ4dUDCAo&uact=5&oq=thanku+cashless+tipping&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEB4QFjIFCAAQhgMyBQgAEIYDMgUIABCGAzIFCAAQhgMyBQgAEIYDOg4IABCPARDqAhCMAxDlAjoOCC4QjwEQ6gIQjAMQ5QI6CwgAEIAEELEDEIMBOggILhCxAxCDAToRCC4QgAQQsQMQgwEQxwEQ0QM6DgguEIAEELEDEMcBENEDOgUIABCABDoLCC4QgAQQsQMQgwE6BQguEIAEOgsILhCxAxCDARDUAjoICAAQgAQQsQM6CwguEIAEELEDENQCOgsILhCABBDHARDRAzoECAAQAzoOCC4QgAQQsQMQgwEQ1AI6CAguEIAEELEDOgcIABCABBAKOgcIABCxAxAKOgQIABAKOgQILhAKOgoILhDHARCvARAKOgoILhDHARCvARANOgQIABANOggIABAeEA0QBToMCAAQHhAPEAgQDRAKOgoIABAeEA8QCBANOgcIIRCgARAKOggIIRAeEBYQHVDuBFiQH2CWIGgBcAB4AIABU4gBrgmSAQIyM5gBAKABAbABCg&sclient=gws-wiz#lrd=0x487605604ae6b115:0x72d4727c1f762f01,1";

        window.open(url);
    },
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHome: true }; 
      this.$emit('return-json', returnJSON);

    },
    doShowMoreInfo(){
    
      // /console.log("show more info!");

      let returnJSON = { showMoreGetInfo: true }; 
      this.$emit('return-json', returnJSON);
      
    },
    doAlert(){
      // /alert("Loaded!");
    },
    doSetMetas(){

      document.title = "Let your clients tip you with their phone - with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "Let your clients tip you with their phone - with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour. You clients simply scan thankU's small, elegant QR badge - no app download or account creation required. Getting tips with thankU couldn't be simpler: tips go directly to you, the recipient. If you have a thankU QR badge at your workplace, just scan the QR code and tap the 'get tips here...' button at the bottom - have your bank details to hand and it takes less than 1 minute... or tap the 'Get free thankU badges' button to have thankU send you our thankU QR badges at no cost.") ;
    
    }
    
  },
  created(){
    this.doSetMetas();
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    // window.addEventListener('resize', this.doFontSizeComputed);
  },
  mounted(){
    this.vhMult = shared.setScreenHeightMultiplier();
    this.doFontSizeComputed();
    shared.saveToUserPath(this.devEnv, "View testimonitals " + new Date().toISOString(), this.globalPushForwardInterval);
    var tudis = "";
    if (window.localStorage.getItem("tudis") !== null){
      tudis = " by user " + window.localStorage.getItem("tudis");
    }
    const params = {
        toEmail: "appnotif@thanku.app",
        subject: "TESTIMONIALS page visited" + tudis + "!" + " " + new Date().toISOString(),
        body: "TESTIMONIALS page visited" + tudis + "!" + " " + new Date().toISOString(),
      };
    Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
    Parse.Cloud.run("sendEmail", params);
  }
}
</script>

